import React, { useState, useEffect } from "react";
import axios from "axios";

const RecruitmentForm = () => {
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    school: "",
    description: "",
    discord: "",
    dispo: "",
  });

  const [message, setMessage] = useState("");
  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => setMessage(""), 2000); // Efface le message après 5 secondes
      return () => clearTimeout(timer); // Nettoie le timer si le composant est démonté ou si `message` change
    }
  }, [message]);

  const handleChange = (e) => {
    const { name, value, options } = e.target;
    if (name === "dispo") {
      // Récupérer les options sélectionnées pour le champ dispo (multiple)
      const selectedOptions = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
      setFormData({ ...formData, [name]: selectedOptions });
    } else {
      // Traitement classique pour un champ avec un seul choix
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedRole =
      localStorage.getItem("selectedRole") || "Not Specified";

    const dataToSend = {
      ...formData,
      poste: selectedRole,
    };

    try {
      const response = await axios.post(
        "https://unity.beratt.eu/api/sendrecruit",
        dataToSend
      );
      console.log("Réponse du serveur:", response.data);
      setMessage("Votre candidature a été envoyée avec succès ! 🎉"); // Afficher un message de confirmation.
      setTimeout(() => window.location.reload(), 2000);
    } catch (error) {
      console.error("Erreur lors de l'envoi de la candidature:", error);
      setMessage(
        "Une erreur est survenue. Veuillez remplir tous les champs ou réessayer."
      );
    }
  };

  return (
    <div className="relative">
      {/* Message en haut à gauche */}
      {message && (
        <div
          className={`absolute bottom-4 left-0 w-full p-4 rounded shadow-lg ${
            message.includes("succès") ? "bg-green-500" : "bg-red-500"
          } text-white`}>
          {message}
        </div>
      )}
      <form onSubmit={handleSubmit} className="p-6 text-white max-w-md mx-auto">
        <h2 className="text-2xl font-bold mb-4 text-center">
          Rejoignez EpiCard
        </h2>
        <div className="mb-4">
          <label className="block font-medium">Nom</label>
          <input
            type="text"
            name="nom"
            value={formData.nom}
            onChange={handleChange}
            className="w-full p-2 rounded bg-white text-black"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block font-medium">Prénom</label>
          <input
            type="text"
            name="prenom"
            value={formData.prenom}
            onChange={handleChange}
            className="w-full p-2 rounded bg-white text-black"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block font-medium">École</label>
          <select
            name="school"
            value={formData.school}
            onChange={handleChange}
            className="w-full p-2 rounded bg-white text-black"
            required>
            <option value="">-- Sélectionnez une ville --</option>
            {[
              "Barcelone",
              "Berlin",
              "Bordeaux",
              "Bruxelles",
              "Cotonou",
              "La Réunion",
              "Lille",
              "Lyon",
              "Madrid",
              "Marseille",
              "Montpellier",
              "Moulins",
              "Mulhouse",
              "Nancy",
              "Nantes",
              "Nice",
              "Paris",
              "Rennes",
              "Strasbourg",
              "Toulouse",
            ].map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block font-medium">Contact discord</label>
          <input
            type="text"
            name="discord"
            value={formData.discord}
            onChange={handleChange}
            className="w-full p-2 rounded bg-white text-black"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block font-medium">Disponibilité</label>
          <select
            name="dispo"
            value={formData.dispo}
            onChange={handleChange}
            className="w-full p-2 rounded bg-white text-black">
            <option value="">-- Sélectionnez une ville --</option>
            {["Week-end", "Semaine", "Temps plein"].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block font-medium">
            Description (vous en quelques mots)
          </label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full p-2 rounded bg-white text-black"
            rows="4"></textarea>
        </div>
        <button
          type="submit"
          className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
          Envoyer
        </button>
      </form>
    </div>
  );
};

export default RecruitmentForm;
