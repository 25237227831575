import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ProfilePage = () => {
  const [userInfo, setUserInfo] = useState({ username: '', coins: 0 });
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [cardNames, setCardNames] = useState([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [isRouletteActive, setIsRouletteActive] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token'); // Récupérer le token dans le localStorage
    if (token) {
      axios
        .post('https://unity.beratt.eu/api/userinfo', { token })
        .then((response) => {
          setUserInfo({
            username: response.data.username,
            coins: response.data.coins,
          });
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des données utilisateur:', error);
        });
    }

    // Initialiser les noms des cartes pour la roulette
    setCardNames(['Carte 1', 'Carte 2', 'Carte 3', 'Carte 4', 'Carte 5', 'Carte 6']);
  }, []);

  // Fonction pour lancer la roulette
  const startRoulette = () => {
    setIsRouletteActive(true);
    setSelectedCardIndex(null);

    // Arrêter la roulette après 6 secondes et choisir une carte aléatoire
    setTimeout(() => {
      setIsRouletteActive(false);
      const randomIndex = Math.floor(Math.random() * cardNames.length);
      setSelectedCardIndex(randomIndex);
    }, 6000);
  };

  // Ouvrir la popup avec le contenu spécifié
  const openPopup = (content) => {
    setPopupContent(content);
    setIsPopupOpen(true);
  };

  // Fermer la popup
  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupContent('');
  };

  return (
    <div className="p-8 bg-gray-100 min-h-screen">
      {/* Informations utilisateur */}
      <div className="mb-8 bg-white p-6 rounded-lg shadow-md flex justify-between items-center">
        <h2 className="text-xl font-semibold">Profil de {userInfo.username}</h2>
        <p className="text-lg">Coins : {userInfo.coins}</p>
      </div>

      {/* Roulette de Booster */}
      <div className="mb-8 bg-white p-6 rounded-lg shadow-md flex flex-col items-center overflow-hidden h-24 relative">
        {/* Flèche pour pointer vers la carte sélectionnée */}
        <div className="absolute top-0 transform -translate-y-8 text-2xl font-bold text-blue-500">↑</div>

        {isRouletteActive ? (
          // Si la roulette est active, défilement horizontal infini
          <div className="animate-horizontal-scroll flex space-x-4">
            {cardNames.map((card, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-24 h-20 bg-gray-200 flex items-center justify-center text-center rounded-lg"
              >
                {card}
              </div>
            ))}
          </div>
        ) : (
          // Si la roulette s'arrête, afficher la carte sélectionnée
          selectedCardIndex !== null && (
            <div className="flex space-x-4">
              {cardNames.map((card, index) => (
                <div
                  key={index}
                  className={`flex-shrink-0 w-24 h-20 ${
                    index === selectedCardIndex ? 'bg-blue-200' : 'bg-gray-200'
                  } flex items-center justify-center text-center rounded-lg`}
                >
                  {card}
                </div>
              ))}
            </div>
          )
        )}

        {/* Bouton Lancer */}
        <button
          className="mt-4 px-6 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          onClick={startRoulette}
          disabled={isRouletteActive}
        >
          Lancer
        </button>
      </div>

      {/* Grille des cartes d'images */}
      <div className="grid grid-cols-6 gap-4">
        {Array.from({ length: 18 }).map((_, index) => (
          <div
            key={index}
            className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center"
          >
            <div className="w-full h-24 bg-gray-200 rounded-md mb-2 flex items-center justify-center">
              <span>Image {index + 1}</span>
            </div>
            <p className="text-center font-medium">Nom {index + 1}</p>
            <button
              className="mt-2 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={() => openPopup(`Contenu de la popup pour Nom ${index + 1}`)}
            >
              Réclamer
            </button>
          </div>
        ))}
      </div>

      {/* Popup */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
            <h3 className="text-lg font-semibold mb-4">Popup</h3>
            <p>{popupContent}</p>
            <button
              className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={closePopup}
            >
              Fermer
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
