import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import backgroundVideo from "../assets/etoiles_bleu.mp4";
import img1 from "../assets/betedesmarais.png";
import wantYou from "../assets/iwantyou.png";
import img2 from "../assets/chimeredefeu.png";
import img3 from "../assets/elfemystique.png";
import img4 from "../assets/poulpe.png";
import img5 from "../assets/yohanuniq.png";
import diamant from "../assets/epicard_logo.png";
import SparkleButtonWithCounter from "./buttonVote";
import william from "../assets/william.jpg";
import ghost from "../assets/ghost.jpg";
import minich from "../assets/minich.jpg";
import rich from "../assets/rich.jpg";
import qrcode from "../assets/qrcodeDiscord.png";
import RecruteText from "./recruteText";

const Home = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Vérifie si le token est présent dans le localStorage
    const token = localStorage.getItem("token");
    if (token) {
      setIsUserLoggedIn(true);
    }
  }, []);

  const handleLogout = () => {
    // Retire le token du localStorage et met à jour l'état
    localStorage.removeItem("token");
    setIsUserLoggedIn(false);
    window.location.reload(); // Redirige vers la page d'accueil après déconnexion
  };

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
    setIsMenuOpen(false); // Ferme le menu après le clic sur un lien
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        overflowX: "hidden",
        scrollbarWidth: "none",
      }}>
      {/* Video en arrière-plan */}
      <video
        src={backgroundVideo}
        autoPlay
        loop
        muted
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: "-1",
        }}
      />

      {/* Contenu principal */}
      <div
        style={{
          width: "100%",
          position: "relative",
          zIndex: "1",
        }}>
        {/* Header avec navigation */}
        <div
          className="fixed flex items-start justify-center"
          style={{
            width: "100%",
            top: "4%",
            zIndex: "2",
          }}>
          <img
            src={diamant}
            alt="diamant"
            style={{
              position: "absolute",
              top: "70%",
              left: "6%",
              width: "5%",
              zIndex: "99",
              transform: "translate(-50%, -50%)",
            }}
          />

          {/* Menu Hamburger pour mobile */}
          <div
            className="absolute right-8 top-4 lg:hidden text-yellow-400 cursor-pointer"
            onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <svg
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round">
              <line x1="3" y1="12" x2="21" y2="12" />
              <line x1="3" y1="6" x2="21" y2="6" />
              <line x1="3" y1="18" x2="21" y2="18" />
            </svg>
          </div>

          {/* Navigation links */}
          <div
            className={`${
              isMenuOpen ? "flex" : "hidden"
            } lg:flex flex-col lg:flex-row items-center text-center lg:text-left bg-opacity-90 bg-gray-800 lg:bg-transparent p-4 lg:p-0 lg:w-2/3 lg:justify-between text-yellow-400 font-bold text-lg transition-transform duration-200`}>
            <span
              onClick={() => {
                setIsPopupOpen(true);
                scrollToSection("vote");
              }}
              className="cursor-pointer hover:scale-110 transition-transform duration-200">
              Join Epicard
            </span>
            <span className="hidden lg:block">&#126;</span>
            <span
              onClick={() => scrollToSection("vote")}
              className="cursor-pointer hover:scale-110 transition-transform duration-200">
              Vote
            </span>
            <span className="hidden lg:block">&#126;</span>
            <span
              onClick={() => scrollToSection("roadmap")}
              className="cursor-pointer hover:scale-110 transition-transform duration-200">
              Road Map
            </span>
            <span className="hidden lg:block">&#126;</span>
            <span
              onClick={() => scrollToSection("pbs")}
              className="cursor-pointer hover:scale-110 transition-transform duration-200">
              PBS
            </span>
            <span className="hidden lg:block">&#126;</span>
            <span
              onClick={() => scrollToSection("dons")}
              className="cursor-pointer hover:scale-110 transition-transform duration-200">
              Dons
            </span>
            <span className="hidden lg:block">&#126;</span>
            <span
              onClick={() => scrollToSection("apropos")}
              className="cursor-pointer hover:scale-110 transition-transform duration-200">
              A propos
            </span>
            <span className="hidden lg:block">&#126;</span>
            {isUserLoggedIn ? (
              <span
                onClick={handleLogout}
                className="cursor-pointer hover:scale-110 transition-transform duration-200">
                Déconnexion
              </span>
            ) : (
              <span
                onClick={() => navigate("/login")}
                className="cursor-pointer hover:scale-110 transition-transform duration-200">
                Connexion
              </span>
            )}
          </div>
        </div>

        {/* Sections */}
        {/* Vote */}
        <div
          id="vote"
          style={{ height: "100vh", position: "relative", zIndex: "-1" }}>
          {/* Popup */}
          {isPopupOpen && (
            <div>
              {/* Overlay grisé */}
              <div
                style={{
                  position: "fixed",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.6)", // Opacité ajustable
                  zIndex: "999", // Derrière la popup
                }}
                onClick={() => setIsPopupOpen(false)} // Ferme la popup si on clique sur l'overlay
              ></div>

              {/* Contenu de la popup */}
              <div
                className="bg-gradient-to-r from-blue-700 to-purple-800"
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: "white",
                  borderRadius: "8px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
                  zIndex: "1000", // Au-dessus de l'overlay
                  textAlign: "center",
                  width: "90%",
                  height: "70%",
                }}>
                <span
                  className="font-bold cursor-pointer"
                  style={{
                    position: "absolute",
                    top: "2%",
                    right: "1.5%",
                    fontSize: "3vh",
                  }}
                  onClick={() => setIsPopupOpen(false)}>
                  X
                </span>
                <div
                  className="flex flex-col items-center justify-between text-white"
                  style={{ width: "100%", height: "100%" }}>
                  <span
                    style={{
                      position: "absolute",
                      fontSize: "2vh",
                      top: "30%",
                      left: "6%",
                      borderRadius: "70px",
                      padding: "0.5%",
                    }}
                    className="cursor-pointer hover:bg-gradient-to-tl from-blue-700 to-purple-800 shadow-xl"
                    onClick={() => navigate("/candidature")}>
                    <RecruteText />
                  </span>
                  <span
                    className="font-bold"
                    style={{ fontSize: "4vh", paddingTop: "2%" }}>
                    Rejoignez l’aventure EpiCard !
                  </span>
                  <img
                    src={wantYou}
                    alt="img1"
                    style={{
                      width: "100vh",
                    }}
                  />
                  <div
                    className="bg-gradient-to-br from-blue-700 to-purple-800 shadow-xl floating1"
                    style={{
                      position: "absolute",
                      top: "27%",
                      right: "3%",
                      textAlign: "start",
                      fontSize: "3vh",
                      borderRadius: "20px",
                      padding: "10px",
                    }}>
                    <ul>
                      <li>1 Chef de projet</li>
                      <li>1 Développeur Devops</li>
                      <li>2 Développeurs moteur de jeu Unity</li>
                      <li>2 Développeurs frontend</li>
                      <li>2 Spécialistes en sécurité</li>
                      <li>2 Experts en animations</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div
            className="absolute"
            style={{ top: "25%", left: "15%", width: "30%" }}>
            <SparkleButtonWithCounter />
          </div>
          <img
            src={img1}
            alt="img1"
            className="floating1"
            style={{
              position: "absolute",
              top: "14%",
              right: "-3%",
              width: "23vh",
            }}
          />
          <img
            src={img2}
            alt="img2"
            className="floating2"
            style={{
              position: "absolute",
              top: "49%",
              right: "-3%",
              width: "23vh",
            }}
          />
          <img
            src={img3}
            alt="img3"
            className="floating3"
            style={{
              position: "absolute",
              top: "21%",
              right: "13%",
              width: "26vh",
            }}
          />
          <img
            src={img4}
            alt="img4"
            className="floating4"
            style={{
              position: "absolute",
              top: "58%",
              right: "13%",
              width: "26vh",
            }}
          />
          <img
            src={img5}
            alt="img5"
            className="floating5"
            style={{
              position: "absolute",
              top: "38%",
              right: "30%",
              width: "28vh",
            }}
          />
        </div>

        {/* RoadMap */}
        <div
          id="roadmap"
          className="flex flex-col items-center justify-center w-full h-screen text-white text-center p-0">
          <div className="flex w-full h-full">
            {/* Phase 1 */}
            <div
              className="flex-1 transition-all duration-300 relative bg-cover bg-center hover:flex-[4] flex flex-col justify-center items-center group"
              style={{ backgroundImage: `url(${william})` }}>
              <div className="absolute w-full h-full flex flex-col items-center justify-center bg-black bg-opacity-0 transition-all">
                <div className="opacity-0 visibility-hidden transition-opacity duration-300 ease-in-out hover:opacity-100 hover:visibility-visible text-white text-2xl font-bold shadow-lg p-4 text-center">
                  <h3 className="text-3xl mb-4">Phase 1</h3>
                  <p>
                    🟢 <strong>Statut :</strong> Terminée
                  </p>
                  <p>
                    Fonctionnalités : Collection de cartes, création de decks,
                    interface de navigation de base.
                  </p>
                  <p>
                    Objectif : Validation du concept, présentation de l'univers
                    d'Epicard.
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center space-y-4">
                  <span className="text-4xl font-bold">Phase 1</span>
                  <span className="bg-green-500 text-white rounded-full p-3">
                    ✔️
                  </span>{" "}
                  {/* Pastille verte */}
                </div>
              </div>
            </div>

            {/* Phase 2 */}
            <div
              className="flex-1 transition-all duration-300 relative bg-cover bg-center hover:flex-[4] flex flex-col justify-center items-center group"
              style={{ backgroundImage: `url(${rich})` }}>
              <div className="absolute w-full h-full flex flex-col items-center justify-center bg-black bg-opacity-0 transition-all">
                <div className="opacity-0 visibility-hidden transition-opacity duration-300 ease-in-out hover:opacity-100 hover:visibility-visible text-white text-2xl font-bold shadow-lg p-4 text-center">
                  <h3 className="text-3xl mb-4">Phase 2</h3>
                  <p>
                    🎯 <strong>Objectif :</strong> Qualité visuelle et fluidité
                    améliorées.
                  </p>
                  <p>
                    Ajouts : Animations, transitions fluides, effets visuels
                    avancés, optimisation de performance.
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center space-y-4">
                  <span className="text-4xl font-bold">Phase 2</span>
                  <span className="bg-red-500 text-white rounded-full p-3">
                    ❌
                  </span>{" "}
                  {/* Pastille rouge */}
                </div>
              </div>
            </div>

            {/* Phase 3 */}
            <div
              className="flex-1 transition-all duration-300 relative bg-cover bg-center hover:flex-[4] flex flex-col justify-center items-center group"
              style={{ backgroundImage: `url(${minich})` }}>
              <div className="absolute w-full h-full flex flex-col items-center justify-center bg-black bg-opacity-0 transition-all">
                <div className="opacity-0 visibility-hidden transition-opacity duration-300 ease-in-out hover:opacity-100 hover:visibility-visible text-white text-2xl font-bold shadow-lg p-4 text-center">
                  <h3 className="text-3xl mb-4">Phase 3</h3>
                  <ul className="list-disc list-inside text-left">
                    <li>
                      <strong>Void Market</strong> : Échange de cartes entre
                      joueurs pour ajouter un aspect économique.
                    </li>
                    <li>
                      <strong>Forge de Cartes</strong> : Fusionner des cartes
                      pour en créer de nouvelles.
                    </li>
                    <li>
                      <strong>Modes de Jeu</strong> : Match à Paris, Mode
                      Casual, Mode Custom.
                    </li>
                  </ul>
                </div>
                <div className="flex flex-col items-center justify-center space-y-4">
                  <span className="text-4xl font-bold">Phase 3</span>
                  <span className="bg-red-500 text-white rounded-full p-3">
                    ❌
                  </span>{" "}
                  {/* Pastille rouge */}
                </div>
              </div>
            </div>

            {/* Phase 4 */}
            <div
              className="flex-1 transition-all duration-300 relative bg-cover bg-center hover:flex-[4] flex flex-col justify-center items-center group"
              style={{ backgroundImage: `url(${ghost})` }}>
              <div className="absolute w-full h-full flex flex-col items-center justify-center bg-black bg-opacity-0 transition-all">
                <div className="opacity-0 visibility-hidden transition-opacity duration-300 ease-in-out hover:opacity-100 hover:visibility-visible text-white text-2xl font-bold shadow-lg p-4 text-center">
                  <h3 className="text-3xl mb-4">Phase 4</h3>
                  <p>
                    🚀 <strong>Objectif :</strong> Version finale avec toutes
                    les fonctionnalités sur Steam.
                  </p>
                  <p>
                    Communauté et Support : Mises à jour régulières, événements
                    en jeu pour maintenir l'intérêt.
                  </p>
                </div>
                <div className="flex flex-col items-center justify-center space-y-4">
                  <span className="text-4xl font-bold">Phase 4</span>
                  <span className="bg-red-500 text-white rounded-full p-3">
                    ❌
                  </span>{" "}
                  {/* Pastille rouge */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* PBS */}
        <section
          id="pbs"
          style={{ paddingTop: "8%" }}
          className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-gray-900 to-gray-700 text-white text-center p-8">
          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            🎯 Plan de Base de Spécification (PBS) 🎯
          </h2>

          <div className="text-left max-w-4xl space-y-8">
            {/* Epicard Overview */}
            <div>
              <h3 className="text-2xl font-semibold text-yellow-400">
                Epicard : Version Unity/Unreal
              </h3>
              <p>
                Le projet Epicard, développé sur Unity et Unreal, vise à offrir
                une expérience unique de jeu de cartes. La version finale inclut
                des fonctionnalités avancées et une interface immersive,
                enrichie d'animations et d'effets visuels fluides.
              </p>
            </div>

            {/* Card System */}
            <div>
              <h3 className="text-2xl font-semibold text-yellow-400">
                Système de Cartes
              </h3>
              <ul className="list-disc list-inside text-lg space-y-2">
                <li>
                  Collection de cartes enrichie et optimisée pour un accès plus
                  intuitif.
                </li>
                <li>
                  <strong>Forge de cartes :</strong> une nouvelle fonctionnalité
                  permettant aux joueurs d'obtenir de nouvelles cartes.
                </li>
                <li>
                  <strong>Void Market :</strong> un marché en ligne où les
                  joueurs peuvent acheter et vendre des cartes.
                </li>
                <li>
                  Création de decks personnalisés avec des options avancées
                  d'interface et de personnalisation.
                </li>
              </ul>
            </div>

            {/* Game Modes */}
            <div>
              <h3 className="text-2xl font-semibold text-yellow-400">
                Modes de Jeu
              </h3>
              <ul className="list-disc list-inside text-lg space-y-2">
                <li>
                  <strong>Casual :</strong> parties amicales sans impact sur le
                  classement.
                </li>
                <li>
                  <strong>Ranked :</strong> parties classées avec système de
                  matchmaking et classement compétitif.
                </li>
                <li>
                  <strong>Custom :</strong> possibilité de créer des parties
                  avec des règles spéciales pour varier les stratégies.
                </li>
                <li>
                  <strong>Match à paris :</strong> mode compétitif où le perdant
                  perd une ou plusieurs cartes aléatoire, ajoutant un niveau de
                  risque.
                </li>
              </ul>
            </div>

            {/* Shop */}
            <div>
              <h3 className="text-2xl font-semibold text-yellow-400">
                Boutique
              </h3>
              <ul className="list-disc list-inside text-lg space-y-2">
                <li>
                  Packs de cartes aléatoires utilisant un système de gacha pour
                  des gains imprévisibles.
                </li>
                <li>
                  <strong>Objets cosmétiques :</strong> personnalisations
                  visuelles pour ses cartes et son profil, rendant chaque
                  collection unique.
                </li>
              </ul>
            </div>

            {/* UI Improvements */}
            <div>
              <h3 className="text-2xl font-semibold text-yellow-400">
                Interface Utilisateur
              </h3>
              <p className="text-lg">
                Conçue sous Unity, l'interface propose :
              </p>
              <ul className="list-disc list-inside text-lg space-y-2">
                <li>
                  Animations et transitions enrichies pour une expérience
                  immersive.
                </li>
                <li>
                  Accès simplifié au Void Market pour des échanges rapides et
                  efficaces.
                </li>
                <li>
                  Gestion avancée des collections et des decks avec une
                  interface intuitive.
                </li>
                <li>
                  Interface de matchmaking dédiée pour chaque mode de jeu.
                </li>
              </ul>
            </div>

            {/* Backend and Server Management */}
            <div>
              <h3 className="text-2xl font-semibold text-yellow-400">
                Backend et Gestion de Serveurs
              </h3>
              <p className="text-lg">
                Pour assurer une performance optimale, le backend gère :
              </p>
              <ul className="list-disc list-inside text-lg space-y-2">
                <li>
                  Gestion avancée des cartes et des decks, y compris les mises à
                  jour et nouvelles fonctionnalités.
                </li>
                <li>
                  Transactions sécurisées sur le Void Market, permettant des
                  échanges fluides et sûrs.
                </li>
                <li>
                  Système de matchmaking pour les modes Ranked et Casual,
                  assurant une expérience équilibrée.
                </li>
                <li>
                  Support des mises à jour futures et organisation d'événements
                  en jeu réguliers.
                </li>
              </ul>
            </div>

            {/* Training Mode */}
            <div>
              <h3 className="text-2xl font-semibold text-yellow-400">
                Entraînement Contre l'Ordinateur
              </h3>
              <p className="text-lg">
                Un mode d'entraînement sera ajouté après la feuille de route
                initiale, permettant aux joueurs de s'exercer avant de rejoindre
                les parties en ligne :
              </p>
              <ul className="list-disc list-inside text-lg space-y-2">
                <li>Trois niveaux de difficulté : facile, moyen, difficile.</li>
                <li>
                  IA optimisée pour permettre aux joueurs d'affiner leurs
                  stratégies.
                </li>
              </ul>
            </div>

            {/* Final Goal */}
            <div>
              <h3 className="text-2xl font-semibold text-yellow-400">
                Objectif Final : Lancement sur Steam
              </h3>
              <p className="text-lg">
                L'objectif final est un lancement sur Steam pour une
                distribution fluide, avec un support communautaire et des mises
                à jour régulières pour faire évoluer le jeu.
              </p>
            </div>
          </div>
        </section>

        {/* Dons */}
        <div
          id="dons"
          className="flex flex-col items-center justify-center min-h-screen bg-opacity-80 bg-gradient-to-b from-purple-800 to-indigo-900 text-white text-center p-8">
          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            🌟 Pourquoi faire un Don ? 🌟
          </h2>
          <p className="text-lg md:text-xl max-w-2xl mb-8 text-yellow-300">
            Epicard est un projet étudiant visant à révolutionner les jeux de
            cartes de bataille entre joueurs. Chaque contribution nous aide à
            créer une expérience de jeu unique pour tous, que vous soyez un
            amateur ou un expert 🎮✨ !
          </p>
          <button
            className="bg-yellow-400 text-black font-semibold py-3 px-8 rounded-lg hover:bg-yellow-300 transition duration-200 shadow-lg transform hover:scale-105"
            onClick={() =>
              window.open(
                "https://donate.stripe.com/7sI6re9bGfvzfwk14b",
                "_blank",
                "noopener,noreferrer"
              )
            }>
            💎 Acheter un Pack Donateur
          </button>
          <p className="mt-4 text-sm md:text-base max-w-xl text-yellow-200">
            En achetant un pack donateur, vous recevrez **500 crédits** 🎉 à
            l'ouverture du jeu et un **rôle spécial** 🎖️ sur notre serveur
            Discord !
          </p>
        </div>

        {/* A propos */}
        <div
          id="apropos"
          style={{ paddingTop: "8%" }}
          className="flex flex-col items-center justify-center min-h-screen bg-opacity-90 bg-gradient-to-r from-blue-700 to-purple-800 text-white text-center p-8">
          <h2 className="text-4xl md:text-5xl font-bold mb-6">
            🎉 Epicard, c'est quoi ? 🎉
          </h2>
          <p className="text-lg md:text-2xl max-w-3xl mb-8 text-blue-200">
            Epicard est un jeu de cartes de combat innovant créé par trois
            passionnés de jeux vidéo : Bastien Rapaud, William Berger, et
            Terence Tresch. 👨‍💻✨ Ces étudiants ont été inspirés par des jeux
            cultes comme Hearthstone et Yu-Gi-Oh! pour développer une nouvelle
            expérience de jeu où la stratégie et la créativité sont au cœur des
            affrontements. Leur objectif ? Réinventer le jeu de cartes
            compétitif pour les joueurs du monde entier 🌍 en apportant une
            dimension unique et personnalisée.
          </p>
          <img
            src={qrcode}
            alt="qrcode"
            className="floating5"
            style={{ borderRadius: "10px" }}
          />
          <p className="mt-4 text-sm md:text-base max-w-xl text-blue-100">
            Découvrez notre Discord{" "}
            <a href="https://discord.gg/4nb5Sa73">
              <u>Epicard Community</u>{" "}
            </a>
            pour plus d'informations concernant le jeu.
          </p>
        </div>

        <style>
          {`
            @keyframes float {
              0% { transform: translateY(0); }
              50% { transform: translateY(-10px); }
              100% { transform: translateY(0); }
            }

            .floating1 { animation: float 7s ease-in-out infinite; }
            .floating2 { animation: float 6s ease-in-out infinite; }
            .floating3 { animation: float 5s ease-in-out infinite; }
            .floating4 { animation: float 4s ease-in-out infinite; }
            .floating5 { animation: float 3s ease-in-out infinite; }
          `}
        </style>
      </div>
    </div>
  );
};

export default Home;
