import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./candidature.css";
import RecruitmentForm from "./popupRecrutement";

const Candidature = ({ items }) => {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
      className="bg-gradient-to-bl from-blue-600 to-red-800">
      {/* Bouton de retour */}
      <div
        className="absolute cursor-pointer hover:scale-110 transition-transform duration-200"
        style={{ top: "1%", right: "2%", zIndex: 1000 }}
        onClick={() => navigate("/")}>
        <span className="text-white font-bold" style={{ fontSize: "3vw" }}>
          X
        </span>
      </div>
      <div
        className="absolute cursor-pointer hover:scale-105 transition-transform duration-200"
        style={{ top: "1%", left: "2%" }}>
        <span className="text-white font-bold" style={{ fontSize: "3vw" }}>
          Choix de votre poste :
        </span>
      </div>
      {/* Popup */}
      {isPopupOpen && (
        <div>
          {/* Overlay grisé */}
          <div
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.6)", // Opacité ajustable
              zIndex: "999", // Derrière la popup
            }}
            onClick={() => setIsPopupOpen(false)} // Ferme la popup si on clique sur l'overlay
          ></div>

          {/* Contenu de la popup */}
          <div
            className="bg-gradient-to-r from-blue-700 to-purple-800"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "white",
              borderRadius: "8px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
              zIndex: "1000", // Au-dessus de l'overlay
              textAlign: "center",
              width: "30%",
              height: "90%",
            }}>
            {/* <span
              className="font-bold cursor-pointer"
              style={{
                position: "absolute",
                top: "2%",
                right: "1.5%",
                fontSize: "3vh",
              }}
              onClick={() => setIsPopupOpen(false)}>
              X
            </span> */}
            <RecruitmentForm />
          </div>
        </div>
      )}

      {/* <div class="mainDiv">
        <input class="inputBtn" type="radio" name="position" />
        <input class="inputBtn" type="radio" name="position" />
        <input class="inputBtn" type="radio" name="position" />
        <input class="inputBtn" type="radio" name="position" />
        <input class="inputBtn" type="radio" name="position" />
        <input class="inputBtn" type="radio" name="position" />

        <div class="carousel1">

          
        </div>
      </div> */}
      <div class="body">
        <div class="slider">
          <input
            type="radio"
            name="slider"
            title="slide1"
            class="slider__nav"
          />
          <input
            type="radio"
            name="slider"
            title="slide2"
            class="slider__nav"
          />
          <input
            type="radio"
            name="slider"
            title="slide3"
            class="slider__nav"
          />
          <input
            type="radio"
            name="slider"
            title="slide4"
            class="slider__nav"
          />
          <input
            type="radio"
            name="slider"
            title="slide5"
            class="slider__nav"
          />
          <input
            type="radio"
            name="slider"
            title="slide6"
            class="slider__nav"
          />
          <div class="slider__inner">
            <div class="slider__contents">
              <div
                className="flex flex-col justify-between bg-white border-4 border-black"
                style={{
                  height: "70%",
                  borderRadius: "20px",
                  padding: "2rem",
                }}>
                <span
                  className="flex font-bold justify-center"
                  style={{ width: "100%", fontSize: "4vh" }}>
                  Développeur moteur de jeu
                </span>
                <span className="text-lg">
                  <b>
                    <u>Missions :</u>
                  </b>
                  <li>Développer et optimiser le moteur du jeu.</li>
                  <li>Maintenir un moteur performant et fonctionnel.</li>

                  <b>
                    <u>Compétences requises :</u>
                  </b>
                  <li>Unity/Unreal, C++, C#.</li>
                  <li>Optimisation et architecture des moteurs de jeu.</li>

                  <b>
                    <u>Qualités personnelles :</u>
                  </b>
                  <li>Capacité à résoudre des problèmes complexes.</li>
                  <li>Curiosité et capacité à apprendre rapidement.</li>
                </span>
                <span
                  className="flex justify-center"
                  style={{ width: "100%", fontSize: "2vh", marginTop: "2%" }}>
                  Titre personnalisé : Maître du Forgecode
                </span>
                <div
                  style={{ marginTop: "auto" }}
                  className="flex justify-center items-end">
                  <button
                    onClick={() => {
                      const title = "Développeur moteur de jeu"; // Changez dynamiquement pour chaque carte
                      localStorage.setItem("selectedRole", title); // Stocke le titre dans le localStorage
                      setIsPopupOpen(true);
                    }}
                    style={{
                      paddingLeft: "8%",
                      paddingRight: "8%",
                      padding: "2%",
                    }}
                    className="cursor-pointer bg-yellow-400 text-black font-semibold rounded-lg hover:bg-yellow-300 transition duration-200 shadow-lg transform hover:scale-105">
                    Postuler
                  </button>
                </div>
              </div>
            </div>
            <div class="slider__contents">
              <div
                className="flex flex-col justify-between bg-white border-4 border-black"
                style={{
                  height: "70%",
                  borderRadius: "20px",
                  padding: "2rem",
                }}>
                <span
                  className="flex font-bold justify-center"
                  style={{ width: "100%", fontSize: "4vh" }}>
                  Expert en animations
                </span>
                <span className="text-lg">
                  <b>
                    <u>Missions :</u>
                  </b>
                  <li>
                    Créer des animations dynamiques pour le gameplay (2D/3D).
                  </li>
                  <li>
                    Intégrer des effets visuels et améliorer l'expérience de
                    jeu.
                  </li>

                  <b>
                    <u>Compétences requises :</u>
                  </b>
                  <li>Outils d’animation (Blender...).</li>
                  <li>Principes d’animation (timing...).</li>
                  <li>
                    Notions de base en animations via code (Unity, Unreal etc.).
                  </li>

                  <b>
                    <u>Qualités personnelles :</u>
                  </b>
                  <li>Sens du détail et de l’esthétique.</li>
                  <li>Capacité à résoudre des problèmes techniques.</li>
                </span>
                <span
                  className="flex justify-center"
                  style={{ width: "100%", fontSize: "2vh", marginTop: "2%" }}>
                  Titre personnalisé : Illusionsiste
                </span>
                <div
                  style={{ marginTop: "auto" }}
                  className="flex justify-center items-end">
                  <button
                    onClick={() => {
                      const title = "Expert en animations"; // Changez dynamiquement pour chaque carte
                      localStorage.setItem("selectedRole", title); // Stocke le titre dans le localStorage
                      setIsPopupOpen(true);
                    }}
                    style={{
                      paddingLeft: "8%",
                      paddingRight: "8%",
                      padding: "2%",
                    }}
                    className="cursor-pointer bg-yellow-400 text-black font-semibold rounded-lg hover:bg-yellow-300 transition duration-200 shadow-lg transform hover:scale-105">
                    Postuler
                  </button>
                </div>
              </div>
            </div>
            <div class="slider__contents">
              <div
                className="flex flex-col justify-between bg-white border-4 border-black"
                style={{
                  height: "70%",
                  borderRadius: "20px",
                  padding: "2rem",
                }}>
                <span
                  className="flex font-bold justify-center"
                  style={{ width: "100%", fontSize: "4vh" }}>
                  Développeur frontend
                </span>
                <span className="text-lg">
                  <b>
                    <u>Missions :</u>
                  </b>
                  <li>
                    Concevoir et intégrer l’interface utilisateur (UI) et le
                    lanceur.
                  </li>
                  <li>Améliorer l’expérience utilisateur (UX).</li>

                  <b>
                    <u>Compétences requises :</u>
                  </b>
                  <li>HTML, CSS, React.js.</li>
                  <li>Sens de l’ergonomie et de l’interface.</li>

                  <b>
                    <u>Qualités personnelles :</u>
                  </b>
                  <li>Attention aux détails et adaptabilité.</li>
                  <li>Esprit analytique pour tester et améliorer.</li>
                </span>
                <span
                  className="flex justify-center"
                  style={{ width: "100%", fontSize: "2vh", marginTop: "2%" }}>
                  Titre personnalisé : Architecte arcanique
                </span>
                <div
                  style={{ marginTop: "auto" }}
                  className="flex justify-center items-end">
                  <button
                    onClick={() => {
                      const title = "Développeur frontend"; // Changez dynamiquement pour chaque carte
                      localStorage.setItem("selectedRole", title); // Stocke le titre dans le localStorage
                      setIsPopupOpen(true);
                    }}
                    style={{
                      paddingLeft: "8%",
                      paddingRight: "8%",
                      padding: "2%",
                    }}
                    className="cursor-pointer bg-yellow-400 text-black font-semibold rounded-lg hover:bg-yellow-300 transition duration-200 shadow-lg transform hover:scale-105">
                    Postuler
                  </button>
                </div>
              </div>
            </div>
            <div class="slider__contents">
              <div
                className="flex flex-col justify-between bg-white border-4 border-black"
                style={{
                  height: "70%",
                  borderRadius: "20px",
                  padding: "2rem",
                }}>
                <span
                  className="flex font-bold justify-center"
                  style={{ width: "100%", fontSize: "4vh" }}>
                  Spécialiste en sécurité
                </span>
                <span className="text-lg">
                  <b>
                    <u>Missions :</u>
                  </b>
                  <li>Tester et sécuriser les systèmes du jeu.</li>
                  <li>Identifier et corriger les failles de sécurité.</li>

                  <b>
                    <u>Compétences requises :</u>
                  </b>
                  <li>Pentesting, cryptographie, protocoles réseau.</li>
                  <li>Notions de cybersécurité.</li>

                  <b>
                    <u>Qualités personnelles :</u>
                  </b>
                  <li>Rigueur et patience dans les recherches.</li>
                  <li>Esprit logique et méthodique.</li>
                </span>
                <span
                  className="flex justify-center"
                  style={{ width: "100%", fontSize: "2vh", marginTop: "2%" }}>
                  Titre personnalisé : Cryptomancien
                </span>
                <div
                  style={{ marginTop: "auto" }}
                  className="flex justify-center items-end">
                  <button
                    onClick={() => {
                      const title = "Spécialiste en sécurité"; // Changez dynamiquement pour chaque carte
                      localStorage.setItem("selectedRole", title); // Stocke le titre dans le localStorage
                      setIsPopupOpen(true);
                    }}
                    style={{
                      paddingLeft: "8%",
                      paddingRight: "8%",
                      padding: "2%",
                    }}
                    className="cursor-pointer bg-yellow-400 text-black font-semibold rounded-lg hover:bg-yellow-300 transition duration-200 shadow-lg transform hover:scale-105">
                    Postuler
                  </button>
                </div>
              </div>
            </div>
            <div class="slider__contents">
              <div
                className="flex flex-col justify-between bg-white border-4 border-black"
                style={{
                  height: "70%",
                  borderRadius: "20px",
                  padding: "2rem",
                }}>
                <span
                  className="flex font-bold justify-center"
                  style={{ width: "100%", fontSize: "4vh" }}>
                  Développeur Devops
                </span>
                <span className="text-lg">
                  <b>
                    <u>Missions :</u>
                  </b>
                  <li>
                    Gérer l’infrastructure, déploiement et optimisation des
                    systèmes.
                  </li>
                  <li>
                    Automatiser les processus de développement et de
                    déploiement.
                  </li>
                  <b>
                    <u>Compétences requises :</u>
                  </b>
                  <li>Outils d’infrastructure (Docker, Kubernetes, etc.).</li>
                  <li>Scripting et automatisation (Bash, Python, etc.).</li>
                  <li>Services cloud (AWS, Azure, etc.), CI/CD.</li>

                  <b>
                    <u>Qualités personnelles :</u>
                  </b>
                  <li>Autonomie et bonne gestion des tâches.</li>
                  <li>Communication claire et esprit d’équipe.</li>
                </span>
                <span
                  className="flex justify-center"
                  style={{ width: "100%", fontSize: "2vh", marginTop: "2%" }}>
                  Titre personnalisé : Systomencien
                </span>
                <div
                  style={{ marginTop: "auto" }}
                  className="flex justify-center items-end">
                  <button
                    onClick={() => {
                      const title = "Développeur Devops"; // Changez dynamiquement pour chaque carte
                      localStorage.setItem("selectedRole", title); // Stocke le titre dans le localStorage
                      setIsPopupOpen(true);
                    }}
                    style={{
                      paddingLeft: "8%",
                      paddingRight: "8%",
                      padding: "2%",
                    }}
                    className="cursor-pointer bg-yellow-400 text-black font-semibold rounded-lg hover:bg-yellow-300 transition duration-200 shadow-lg transform hover:scale-105">
                    Postuler
                  </button>
                </div>
              </div>
            </div>
            <div class="slider__contents">
              <div
                className="flex flex-col justify-between bg-white border-4 border-black"
                style={{
                  height: "70%",
                  borderRadius: "20px",
                  padding: "2rem",
                }}>
                <span
                  className="flex font-bold justify-center"
                  style={{ width: "100%", fontSize: "4vh" }}>
                  Chef de projet
                </span>
                <span className="text-lg">
                  <b>
                    <u>Missions :</u>
                  </b>
                  <li>Coordonner l’équipe et suivre les plannings.</li>
                  <li>Garantir la réalisation des objectifs du projet.</li>

                  <b>
                    <u>Compétences requises :</u>
                  </b>
                  <li>Gestion de projet (Agile, Trello, Jira, etc.).</li>
                  <li>Organisation et suivi des tâches.</li>

                  <b>
                    <u>Qualités personnelles :</u>
                  </b>
                  <li>Leadership et sens de l’organisation.</li>
                  <li>Proactivité et bonne communication.</li>
                </span>
                <span
                  className="flex justify-center"
                  style={{ width: "100%", fontSize: "2vh", marginTop: "2%" }}>
                  Titre personnalisé : Maitre de guilde
                </span>
                <div
                  style={{ marginTop: "auto" }}
                  className="flex justify-center items-end">
                  <button
                    onClick={() => {
                      const title = "Chef de projet"; // Changez dynamiquement pour chaque carte
                      localStorage.setItem("selectedRole", title); // Stocke le titre dans le localStorage
                      setIsPopupOpen(true);
                    }}
                    style={{
                      paddingLeft: "8%",
                      paddingRight: "8%",
                      padding: "2%",
                    }}
                    className="cursor-pointer bg-yellow-400 text-black font-semibold rounded-lg hover:bg-yellow-300 transition duration-200 shadow-lg transform hover:scale-105">
                    Postuler
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Candidature;
