import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home"; // Assurez-vous d'importer le bon chemin pour Home
import Login from "./pages/login"; // Importez le composant Login que vous avez créé
import Register from "./pages/register";
import Profil from "./pages/profil";
import Candidature from "./pages/candidature";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profil" element={<Profil />} />
        <Route path="/candidature" element={<Candidature />} />
      </Routes>
    </div>
  );
};

export default App;
